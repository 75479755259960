

















































































































































































































































































































import { Component, Prop, PropSync, Vue, Watch, Ref } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { curveTypeFormula } from "@/consts/curveConfigDefaults";
import numericFilter from "Utilities/numericFilter";
import _ from "underscore";
//  components
import VesselSelectSimple from "@/components/VesselSelectSimple.vue";
import SFOCPowerTierDataEditDialog from "@/components/referencecurves/SFOCPowerTierDataEditDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
// types
import { CurveFunction } from "@/types/PowerSpeedCurveConfig";
import { Vessel } from "@/types/Vessel";
import { Company } from "@/types/company";
import { SFOCPowerCurveConfig, TierData, EngineTier } from "@/types/SFOCPowerCurveConfig";
//  clients
import VesselsClient from "Clients/vessels-client";
//  modules
import CompaniesModule from "@/store/clients/Companies.module";
import UserModule from "@/store/clients/User.module";
import SFOCPowerCurveClient from "@/Scripts/clients/sfoc-power-curve-client";

const Companies = getModule(CompaniesModule, store);
const User = getModule(UserModule, store);

@Component({
  components: {
    VesselSelectSimple,
    SFOCPowerTierDataEditDialog,
    ConfirmDialog,
  },
})
export default class SFOCPowerConfigEditDialog extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;
  @PropSync("value", { required: true }) isOpen!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true }) config!: SFOCPowerCurveConfig;
  @Prop({ required: true }) allVessels!: Vessel[];
  // @Prop({ required: true }) currentCompanyVessels!: Vessel[];
  selectedTab = 0;
  clonedConfig: SFOCPowerCurveConfig | null = null;
  selectedVessels: Vessel[] = [];
  selectedCompanyId: number | null = null;
  showAllCompany: Partial<Company> = { name: "Show All Vessels", id: 0 };

  editTierDataDialog = false;
  tierToEdit: TierData | null = null;

  headers = [
    {
      text: "Tier Name",
      value: "tier",
      class: "v-data-table-column-header",
      width: "33%",
      sortable: true,
    },
    {
      text: "Tier value",
      value: "tierValue",
      class: "v-data-table-column-header",
      width: "33%",
    },
    {
      text: "Tier curves",
      value: "curves",
      class: "v-data-table-column-header",
      width: "14%",
    },
    {
      text: "Edit",
      value: "edit",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Delete",
      value: "delete",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
  ];
  searchQuery: string | null = null;
  debounceLoader = false;
  currentCompanyVessels: Vessel[] = [];

  @Watch("config")
  handleConfigChange(): void {
    this.clonedConfig = JSON.parse(JSON.stringify(this.config));
    const alreadySelectedVesselIds = this.clonedConfig?.vesselIds;
    this.selectedVessels = this.allVessels.filter(v => alreadySelectedVesselIds?.some(i => i === v.id));
  }

  get companies(): Company[] {
    return Companies.companies;
  }

  get userCompanyId(): number | null {
    return User.userCompanyId;
  }

  get filteredVessels(): Vessel[] {
    if (this.searchQuery == null) return this.selectedCompanyId === 0 ? this.allVessels : this.currentCompanyVessels;
    const vessels = this.selectedCompanyId === 0 ? this.allVessels : this.currentCompanyVessels;
    return vessels.filter(v => v.name.toLowerCase().includes((this.searchQuery as string).toLowerCase()));
  }

  get isNoData(): boolean {
    return Boolean(this.clonedConfig?.tiers.length === 0);
  }

  numericFilter(event: any): number {
    return numericFilter(event);
  }

  async deleteTierData(tier: TierData): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete Tier Data", "Are you sure you want to delete this Tiear Data?");
    if (confirmed) {
      if (!this.clonedConfig) return;
      const index = this.clonedConfig.tiers.indexOf(tier);
      if (index > -1) this.clonedConfig.tiers.splice(index, 1);
    }
  }

  updateSearchQueryInput(newValue: string): void {
    this.debounceLoader = true;
    this.searchQuery = newValue;
    setTimeout(() => (this.debounceLoader = false), 500);
  }

  doSearchDebounced = _.debounce(this.updateSearchQueryInput, 1000);

  addNewTier(): void {
    const newTier: TierData = {
      id: 0,
      configId: this.config.id,
      tier: EngineTier.Tier2,
      tierValue: 0,
      curves: [],
    };
    this.clonedConfig?.tiers.push(newTier);
    this.openTierDataEditModal(newTier);
  }

  curveFormula(curveFunction: CurveFunction): string {
    return curveTypeFormula[curveFunction];
  }

  async saveConfig(): Promise<void> {
    if (this.clonedConfig) {
      this.clonedConfig.vesselIds = this.selectedVessels.map(v => v.id);
      await SFOCPowerCurveClient.updateConfig(this.clonedConfig);
    }
    this.isOpen = false;
    this.$emit("onConfigSave");
  }

  discardChanges(): void {
    this.handleConfigChange();
    this.isOpen = false;
  }

  openTierDataEditModal(tier: TierData): void {
    this.editTierDataDialog = true;
    this.tierToEdit = tier;
  }

  async onCompanyChanged(): Promise<void> {
    this.currentCompanyVessels = await VesselsClient.getVesselsByCompanyId(this.selectedCompanyId);
  }

  handleTierDataSave(updatedTierData: TierData): void {
    if (!this.clonedConfig) return;
    const index = this.clonedConfig.tiers.findIndex(c => c.id === updatedTierData.id);
    if (index > -1) {
      this.clonedConfig.tiers.splice(index, 1, updatedTierData);
      this.config.tiers.splice(index, 1, updatedTierData);
    }
  }

  async created(): Promise<void> {
    await Companies.refreshCompanies();
    this.currentCompanyVessels = await VesselsClient.getVessels();
    this.companies.unshift(this.showAllCompany as Company);
    this.selectedCompanyId = this.userCompanyId;
  }
}
